import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppInputsValidationProvider = _resolveComponent("AppInputsValidationProvider")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    title: 'Edit bundle',
    opened: _ctx.opened,
    onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('hide')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "mt-4 pt-2",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_AppInputsValidationProvider, { onChangeErrorState: _ctx.changeErrorState }, {
          default: _withCtx(() => [
            _createVNode(_component_AppInput, {
              id: 'bundle-name',
              modelValue: _ctx.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
              "input-type": "material",
              required: true,
              "validation-type": "notEmptyString",
              type: "string"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["onChangeErrorState"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AppButton, {
            disabled: !_ctx.valid,
            type: "secondary",
            size: "xl",
            loading: _ctx.loading,
            onClick: _ctx.submitHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Confirm ")
            ]),
            _: 1
          }, 8, ["disabled", "loading", "onClick"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["opened"]))
}